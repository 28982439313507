import dayjs from 'dayjs';
import './GigantishReportChartColumns.less';
import { daysToPercentage } from '../GigantishReport';

interface Props {
  boundary: {
    start: dayjs.Dayjs;
    end: dayjs.Dayjs;
  };
}

export const GigantishReportChartColumns = ({ boundary }: Props) => {
  const totalDaysWithinBoundary = boundary.end.diff(boundary.start, 'days') + 1;
  const totalMonths = boundary.end.diff(boundary.start, 'month') + 1;
  const numberOfColumns = Math.min(totalMonths, 6);

  // Distribute months evenly across columns, handling remainder
  const baseMonthsPerColumn = Math.floor(totalMonths / numberOfColumns);
  const remainingMonths = totalMonths % numberOfColumns;

  const columns = Array.from({ length: numberOfColumns }, (_, i) => {
    // Add an extra month to earlier columns if there are remaining months
    const extraMonth = i < remainingMonths ? 1 : 0;
    const monthsInThisColumn = baseMonthsPerColumn + extraMonth;

    const theBeginning = boundary.start
      .add(i * baseMonthsPerColumn + Math.min(i, remainingMonths), 'month')
      .startOf('month');

    const theEnd = theBeginning
      .add(monthsInThisColumn - 1, 'month')
      .endOf('month');

    return {
      start: theBeginning,
      end: dayjs(theEnd).isBefore(boundary.end) ? theEnd : dayjs(boundary.end),
      days: theEnd.diff(theBeginning, 'day') + 1,
    };
  });

  const today = dayjs();
  const daysSinceLeftBoundary = today.diff(boundary.start, 'days');
  const todayWithinBoundary =
    daysSinceLeftBoundary >= 0 &&
    daysSinceLeftBoundary <= totalDaysWithinBoundary;

  return (
    <>
      {columns.map((column, index) => (
        <div
          className="GigantishReportChartColumns__column"
          key={index}
          style={{
            width: daysToPercentage(column.days, totalDaysWithinBoundary),
          }}
          title={`${column.start.format('YYYY MM DD')} to ${column.end.format('YYYY MM DD')} (${column.days})`}
        >
          <div>{column.start.format('YYYY MMM')}</div>
        </div>
      ))}
      {todayWithinBoundary && (
        <div
          className="GigantishReport__todayLine"
          style={{
            left: daysToPercentage(
              daysSinceLeftBoundary,
              totalDaysWithinBoundary
            ),
          }}
        >
          <div className="GigantishReport__todayLineLabel">Today</div>
        </div>
      )}
    </>
  );
};
