import { SkaDetailsContent } from '../../../team/sprint/planning/components/mitemPlanningBoard/skaDetails/SkaDetailsContent';

import cx from 'classnames';
import { Btn } from '../../../../components/Button';
import { CloseOutlined } from '@ant-design/icons';
import './SprintKeyActivityDetails.less';
import Skeleton from 'react-loading-skeleton';

interface Props {
  onClose: () => void;
}

export const SprintKeyActivityDetailsSkeleton = ({ onClose }: Props) => {
  return (
    <div className={cx('SprintKeyActivityDetails')}>
      <div className="SprintKeyActivityDetails__header">
        <div className="SprintKeyActivityDetails__statusTagWrapper">
          <Skeleton width={100} height={30} borderRadius={14} />
        </div>

        <Btn
          shape="circle"
          type="text"
          onClick={onClose}
          className="SprintKeyActivityDetails__closeButton"
          icon={<CloseOutlined />}
        />
      </div>

      <SkaDetailsContent.skeleton />
    </div>
  );
};
