import { SelectionIndicator } from '../../appPages/company/strategicPlan/components/SelectionIndicator';

interface Props {
  id?: string;
  value?: TagColorBox;
  onChange?: (tagColor: TagColorBox) => void;
}

export enum TagColorBox {
  blue = '#CEE7FF',
  violet = '#D9D9FF',
  purple = '#EECAFF',
  pink = '#FFD0E7',
  red = '#FFB9B9',
  yellow = '#F4E3B6',
  green = '#C6F4B6',
  teal = '#A4F4E1',
}

export const ColorPicker = ({ id, value, onChange }: Props) => {
  return (
    <span id={id} className="flx flx--row flx--gap--xs mb">
      {Object.values(TagColorBox).map((color, i) => {
        return (
          <SelectionIndicator key={i} active={value === color}>
            <div
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') onChange?.(color);
              }}
              className="colorBox"
              style={{ backgroundColor: color }}
              onClick={() => {
                onChange?.(color);
              }}
            ></div>
          </SelectionIndicator>
        );
      })}
    </span>
  );
};
