import {
  CheckCircleFilled,
  CloseCircleFilled,
  MinusCircleFilled,
  PauseCircleFilled,
} from '@ant-design/icons';
import { Drawer, Spin, Tooltip, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DisplayDate } from '../../../../../components/DisplayDate';
import { DisplayName } from '../../../../../components/DisplayName';
import { DrawerTitle } from '../../../../../components/DrawerTitle';
import {
  GetTeamMembersForTeamDetailsDrawerDocument,
  PeriodStatus,
  TeamMemberRoles,
} from '../../../../../generated/graphql';
import { friendlyDate } from '../../../../../services/dateFormats';
import { mitemStatusColor } from '../../../../../styleVars';
import { TitleWithTooltip } from '../../../../team/setup/components/TitleWithTooltip';
import './TeamDetailsDrawer.less';

import { useTeamActiveStatusDetailsLazy } from '../../../../../hooks/useTeamActiveStatusDetails';
import dayjs from 'dayjs';
import { TeamKeyActivityPerformanceTag } from './TeamKeyActivityPerformanceTag';
import { TeamMigPerformanceTag } from './TeamMigPerformanceTag';
import { gql, useLazyQuery } from '@apollo/client';

interface Props {
  teamId: string;
  comment?: string;
  analysis?: string;
  showTeamDetails: boolean;
  setShowTeamDetails: (teamId: string | null) => void;
}

export const TeamDetailsDrawer = ({
  teamId,
  comment,
  analysis,
  showTeamDetails,
  setShowTeamDetails,
}: Props) => {
  const { t } = useTranslation();
  const [fetchMembers, { loading: loadingMembers, data: memberData }] =
    useLazyQuery(GetTeamMembersForTeamDetailsDrawerDocument);
  const [fetchTeamDetails, { data, loading }] =
    useTeamActiveStatusDetailsLazy();

  useEffect(() => {
    if (showTeamDetails) {
      fetchTeamDetails({ variables: { teamId } });
    }
  }, [showTeamDetails, fetchTeamDetails, teamId]);

  useEffect(() => {
    if (showTeamDetails) {
      fetchMembers({ variables: { teamId } });
    }
  }, [showTeamDetails, fetchMembers, teamId]);

  const members = memberData?.team2.members;
  const leaders = members?.filter((member) =>
    member.roles.includes(TeamMemberRoles.TEAM_LEADER)
  );

  const meetings = [
    ...(data?.accelerationMeetingOverviewTeamV2.lastMeetingsDetails ?? []),
  ].reverse();

  return (
    <Drawer
      title={
        <DrawerTitle> {t('TeamDetailsDrawer.meetingInsights')}</DrawerTitle>
      }
      placement="right"
      closable={true}
      width={600}
      onClose={() => setShowTeamDetails(null)}
      open={showTeamDetails}
      destroyOnClose
    >
      <Spin spinning={loading || loadingMembers} delay={300}>
        <div>
          <div className="TeamDetailsDrawer__borderedArea mb ">
            <TextRow
              className="mb"
              title={t('TeamDetailsDrawer.teamName')}
              description={
                <Link
                  to={`/team/${data?.accelerationMeetingOverviewTeamV2.id}`}
                >
                  {data?.accelerationMeetingOverviewTeamV2.name}
                </Link>
              }
            />

            <TextRow
              title={t('TeamDetailsDrawer.leaders', { count: leaders?.length })}
              description={
                leaders?.length !== 0 ? (
                  leaders?.map((leader, index) => {
                    return (
                      <div key={index}>
                        <DisplayName user={leader.user.data} />
                      </div>
                    );
                  })
                ) : (
                  <div>-</div>
                )
              }
            />
          </div>
          <div className="TeamDetailsDrawer__borderedArea mb">
            <TextRow
              className="mb"
              title={
                <TitleWithTooltip
                  title={t('common.active')}
                  tooltipContent={t('TeamDetailsDrawer.tooltips.active')}
                />
              }
              description={
                data?.accelerationMeetingOverviewTeamV2.active ? (
                  <span>{t('TeamDetailsDrawer.yes')}</span>
                ) : (
                  <span>{t('TeamDetailsDrawer.no')}</span>
                )
              }
            />
            <TextRow
              className="mb"
              title={t('TeamDetailsDrawer.lastMeeting')}
              description={
                data?.accelerationMeetingOverviewTeamV2
                  .mostRecentMeetingDate ? (
                  <>
                    <span
                      className="space--r"
                      title={friendlyDate(
                        data?.accelerationMeetingOverviewTeamV2
                          .mostRecentMeetingDate
                      )}
                    >
                      {data?.accelerationMeetingOverviewTeamV2
                        .mostRecentMeetingDate &&
                        dayjs(
                          data.accelerationMeetingOverviewTeamV2
                            .mostRecentMeetingDate
                        ).fromNow()}
                    </span>
                    <span className="txt--secondary">
                      {friendlyDate(
                        data?.accelerationMeetingOverviewTeamV2
                          .mostRecentMeetingDate
                      )}
                    </span>
                  </>
                ) : (
                  t('TeamDetailsDrawer.neverStarted')
                )
              }
            />
            <TextRow
              className="mb"
              title={
                <TitleWithTooltip
                  title={t('TeamDetailsDrawer.summary')}
                  tooltipContent={t('TeamDetailsDrawer.tooltips.comment')}
                />
              }
              description={comment}
            />
            <TextRow
              className="mb--l"
              title={t('TeamDetailsDrawer.analysis')}
              description={analysis}
            />
            <TextRow
              title={
                <TitleWithTooltip
                  title={t('TeamDetailsDrawer.previousMeetings')}
                  tooltipContent={
                    <div>
                      <div className="mb">
                        {t('TeamDetailsDrawer.tooltips.previousMeetings')}
                      </div>
                      <div>
                        <CheckCircleFilled
                          className="mr"
                          style={{ color: mitemStatusColor.COMPLETED }}
                        />
                        {t('TeamDetailsDrawer.tooltips.meetingHeld')}
                      </div>
                      <div>
                        <CloseCircleFilled
                          className="mr"
                          style={{ color: mitemStatusColor.NOT_COMPLETED }}
                        />
                        {t('TeamDetailsDrawer.tooltips.meetingNotHeld')}
                      </div>
                      <div>
                        <PauseCircleFilled
                          className="mr"
                          style={{ color: mitemStatusColor.ARCHIVED }}
                        />
                        {t('TeamDetailsDrawer.tooltips.offWeek')}
                      </div>
                      <div>
                        <MinusCircleFilled
                          className="mr"
                          style={{ color: mitemStatusColor.ARCHIVED }}
                        />
                        {t('TeamDetailsDrawer.tooltips.teamNotStarted')}
                      </div>
                    </div>
                  }
                />
              }
              description={
                <div className="flx flx--jc-space-between">
                  {meetings.map((meeting, index) => {
                    switch (meeting.periodStatus) {
                      case PeriodStatus.MEETING_MISSED:
                        return (
                          <MeetingMarker key={index}>
                            <Tooltip
                              title={
                                <DisplayDate date={meeting?.periodStartAt} />
                              }
                            >
                              <CloseCircleFilled
                                style={{
                                  color: mitemStatusColor.NOT_COMPLETED,
                                }}
                              />
                            </Tooltip>
                          </MeetingMarker>
                        );
                      case PeriodStatus.MEETING_HELD:
                        return (
                          <MeetingMarker key={index}>
                            <Tooltip
                              title={
                                <DisplayDate date={meeting.periodStartAt} />
                              }
                            >
                              <CheckCircleFilled
                                style={{
                                  color: mitemStatusColor.COMPLETED,
                                }}
                              />
                            </Tooltip>
                          </MeetingMarker>
                        );
                      case PeriodStatus.NO_PLANNED_MEETING:
                        return (
                          <MeetingMarker key={index}>
                            <Tooltip
                              title={t(
                                'TeamDetailsDrawer.tooltips.noActivities'
                              )}
                            >
                              <CloseCircleFilled
                                style={{
                                  color: mitemStatusColor.NOT_COMPLETED,
                                }}
                              />
                            </Tooltip>
                          </MeetingMarker>
                        );
                      case PeriodStatus.OFF_WEEK:
                        return (
                          <MeetingMarker key={index}>
                            <Tooltip
                              title={t('TeamDetailsDrawer.tooltips.offWeek')}
                            >
                              <PauseCircleFilled
                                style={{ color: mitemStatusColor.ARCHIVED }}
                              />
                            </Tooltip>
                          </MeetingMarker>
                        );
                      case PeriodStatus.NEVER_STARTED:
                      default:
                        return (
                          <MeetingMarker key={index}>
                            <Tooltip
                              title={t(
                                'TeamDetailsDrawer.tooltips.teamNotStarted'
                              )}
                            >
                              <MinusCircleFilled
                                style={{ color: mitemStatusColor.ARCHIVED }}
                              />
                            </Tooltip>
                          </MeetingMarker>
                        );
                    }
                  })}
                </div>
              }
            />
          </div>

          <div className="TeamDetailsDrawer__borderedArea mb">
            <TextRow
              title={t('TeamDetailsDrawer.mig')}
              className="mb"
              description={
                <TeamMigPerformanceTag
                  teamId={teamId}
                  fetchDetails={showTeamDetails}
                />
              }
            />
            <TextRow
              title={t('TeamDetailsDrawer.keyActivity')}
              className="mb"
              description={
                <TeamKeyActivityPerformanceTag
                  teamId={teamId}
                  fetchDetails={showTeamDetails}
                />
              }
            />

            <TextRow
              title={
                <TitleWithTooltip
                  title={t('TeamDetailsDrawer.activeUsers')}
                  tooltipContent={t('TeamDetailsDrawer.activeUsersTooltip')}
                />
              }
              description={t('TeamDetailsDrawer.activeUsersCounter', {
                active: data?.accelerationMeetingOverviewTeamV2.activeMembers,
                total: members?.length,
              })}
            />
          </div>
        </div>
      </Spin>
    </Drawer>
  );
};

interface TextRowProps {
  title: React.ReactNode;
  description: React.ReactNode;
  className?: string;
}

const TextRow = ({ title, description, className }: TextRowProps) => (
  <div className={'flx ' + className}>
    <div style={{ minWidth: 180 }}>
      <Typography.Text type="secondary">{title}</Typography.Text>
    </div>
    <div className="flx flx--ai-center fullWidth">
      <Typography.Text className="fullWidth">{description}</Typography.Text>
    </div>
  </div>
);

const MeetingMarker: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => (
  <div className="flx">
    <div className="flx flx--ai-center">{children}</div>
  </div>
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TEAM_DETAILS_DRAWER_MEMBER = gql`
  query getTeamMembersForTeamDetailsDrawer($teamId: ID!) {
    team2(teamId: $teamId) {
      archived
      name
      id
      domainId {
        tenantId
        itemId
      }
      rev
      members {
        roles
        user {
          id
          data {
            name
            email
            displayName
            initials
          }
        }
      }
    }
  }
`;
