import { gql, PureQueryOptions, useMutation } from '@apollo/client';
import { Divider, Drawer, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DrawerTitle } from '../../../../../components/DrawerTitle';
import { ErrorAlert } from '../../../../../components/ErrorAlert';
import { LearnMoreLink } from '../../../../../components/LearnMoreLink';
import { SKADrawerConfirmCloseAlert } from '../../../../../components/SKADrawerConfirmCloseAlert';
import {
  CreateMitemDrawer_MitemFragment,
  CreateSkaDocument,
  GetSprintKeyActivitiesStatsForMilestoneCardDocument,
  SprintKaInput,
} from '../../../../../generated/graphql';
import { useAccelerationDay } from '../../../../../hooks/useAccelerationDay';
import { useSprintTerms } from '../../../../../hooks/useSprintTerms';
import { standardDateFormat } from '../../../../../services/dateFormats';
import { showNotification } from '../../../../../services/fetchNotificationProperties';
import { useSprintPlanningData } from '../../../common/hooks/useSprintPlanningData';
import './CreateMitemDrawer.less';
import { getSprintDateStatus } from './getSprintDateStatus';
import { SkaInitialValues, SprintKaForm } from './SprintKaForm';
import { howweErrorParser } from '../../../../../services/howweErrorParser';
import { MIG_ASSOCIATION_OTHER } from '../SprintPlanningPage';
import { useTenantDetails } from '../../../../../hooks/useTenantDetails';

interface Props {
  teamId: string;
  showModal: boolean;
  onCompleted?: (data: CreateMitemDrawer_MitemFragment) => void;
  onCancel: () => void;
  showHelpLinks?: boolean;
  prefilledValues?: SkaInitialValues;
  refetchQueries?: Array<string | PureQueryOptions>;
}

export const CreateMitemDrawer = ({
  teamId,
  showModal,
  onCompleted,
  onCancel,
  prefilledValues,
  refetchQueries = [],
  showHelpLinks = true,
}: Props) => {
  const { t } = useTranslation();
  const { features } = useTenantDetails();
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [confirmed, setConfirmed] = useState<boolean>(false);

  // this is used to force the form to re-render when a new SKA is created
  // this removes the need for the form instance here and reduces the complexity with form state
  const [createdSkas, setCreatedSkas] = useState(0);

  const handleCancel = () => {
    if (formIsDirty) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
      onCancel();
    }
  };
  const { data: sprintData, error } = useSprintPlanningData(teamId);
  const { data: termsData } = useSprintTerms(teamId);
  const terms = termsData ?? [];

  const resetConfirmationData = () => {
    setFormIsDirty(false);
    setShowWarning(false);
    setConfirmed(false);
  };

  const { data: accelerationDay } = useAccelerationDay(teamId);
  useEffect(() => {
    if (confirmed) {
      resetConfirmationData();
      onCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmed]);

  // TODO: Should be changed, need to handle intercom on a higher level
  const isAccMeeting = window.location.href.includes('acceleration-meeting');

  useEffect(() => {
    if (!isAccMeeting) {
      (window as any).Intercom?.('update', {
        hide_default_launcher: showModal,
      });

      // sometimes the drawer is unmounted rather than getting the prop showModal={false}
      // this will make sure we show the intercom widget again in that case
      return () =>
        (window as any).Intercom?.('update', {
          hide_default_launcher: false,
        });
    }

    if (!showModal) {
      setFormIsDirty(false);
      setShowWarning(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal, isAccMeeting]);

  const [createSKA, { loading: createPending }] = useMutation(
    CreateSkaDocument,
    {
      onCompleted: (data) => {
        onCompleted?.(data.createSprintKeyActivity);
        showNotification('success', {
          message: t('CreateMitemDrawer.saveSuccess'),
          description: data.createSprintKeyActivity.name,
          placement: 'top',
        });
        resetConfirmationData();
        setCreatedSkas((skas) => skas + 1);
      },
      onError: (error) => {
        const howweErrors = howweErrorParser(error);

        showNotification('error', {
          message: t('CreateMitemDrawer.saveError'),
          description: (
            <strong>
              <ul>
                {howweErrors?.map((e, i) => <li key={i}>{e.translation}</li>)}
              </ul>
            </strong>
          ),
        });
      },
      update(cache, { data }) {
        if (data?.createSprintKeyActivity) {
          cache.modify({
            fields: {
              sprintKeyActivities(existingMItems = []) {
                const newMItemRef = cache.writeFragment({
                  data: data.createSprintKeyActivity,
                  fragment: CREATE_MITEM_DRAWER_MITEM,
                });
                return {
                  sprintKeyActivities: [
                    ...existingMItems.sprintKeyActivities,
                    newMItemRef,
                  ],
                };
              },
            },
          });
        }
      },
    }
  );

  const handleCreateMitem = (mitem: any) => {
    let tags = mitem.tags;
    let noMigAssociation: string | null | undefined = mitem.noMigAssociation;
    const {
      supportsMigId,
      supportsInitiativeIds,
      supportsMilestoneIds,
      ...rest
    } = mitem;
    const supportsInitiativeIdsToBeSubmitted =
      (supportsInitiativeIds as (string | null | undefined)[]) ?? [];

    const noMigSelected =
      supportsMigId === MIG_ASSOCIATION_OTHER || supportsMigId == null;

    const supportsMilestoneIdsToBeSubmitted =
      (supportsMilestoneIds as (string | null | undefined)[]) ?? [];

    const skaToSubmit: SprintKaInput = {
      ...rest,
      tags,
      supportsMigIds: noMigSelected ? [] : [{ id: supportsMigId }],
      noMigAssociation: noMigSelected ? noMigAssociation : null,
      deadline: standardDateFormat(mitem.deadline),
      supportsMilestoneIds: supportsMilestoneIdsToBeSubmitted
        .filter((i) => i != undefined)
        .filter((i) => i !== ''),
    };

    if (features.tenantInitiativesEnabled) {
      skaToSubmit.supportsInitiative2Ids = supportsInitiativeIdsToBeSubmitted
        .filter((i) => i != undefined)
        .filter((i) => i !== '');
    }

    createSKA({
      variables: {
        teamId,
        sprintKeyActivity: skaToSubmit,
      },
      refetchQueries: (response) => {
        const supportsMilestonesStatsRefetchQueries =
          response.data?.createSprintKeyActivity?.supportsMilestones.map(
            (sm) => ({
              query: GetSprintKeyActivitiesStatsForMilestoneCardDocument,
              variables: {
                teamId: teamId,
                milestoneId: sm.domainId.itemId,
              },
            })
          ) ?? [];

        return [...refetchQueries, ...supportsMilestonesStatsRefetchQueries];
      },
    });
  };
  const currentSprint =
    sprintData?.currentSprintAndOnward && sprintData?.currentSprintAndOnward[0];
  const loading = !currentSprint || !accelerationDay;

  const status =
    prefilledValues?.deadline && currentSprint && accelerationDay
      ? getSprintDateStatus({
          selectedDate: prefilledValues.deadline,
          accelerationDay,
          currentSprint,
          terms,
        })
      : null;

  return (
    <Drawer
      title={
        <DrawerTitle className="CreateMitemDrawer__title">
          {t('CreateMitemDrawer.modalTitle')}
        </DrawerTitle>
      }
      open={showModal}
      onClose={handleCancel}
      width={420}
      destroyOnClose
      styles={{
        header: { backgroundColor: '#eaeaea' },
        content: {
          borderLeft: '8px solid #eaeaea',
        },
      }}
    >
      {showWarning && (
        <SKADrawerConfirmCloseAlert
          onConfirm={() => setConfirmed(true)}
          onClose={() => setShowWarning(false)}
        />
      )}
      {!loading && (
        <SprintKaForm
          key={'activityNumber' + createdSkas}
          initialValues={prefilledValues}
          teamId={teamId}
          isEdit={false}
          mitemStatus={status}
          submitPending={createPending}
          onSubmit={handleCreateMitem}
          onCancel={handleCancel}
          setIsFormDirty={setFormIsDirty}
        />
      )}

      {showHelpLinks && (
        <>
          <Divider />
          <Typography.Title level={4} className="pb">
            {t('CreateMitemDrawer.helpLinks.learnMore')}
          </Typography.Title>
          <div className="pb">
            <LearnMoreLink
              urlTemplate="https://help.howwe.io/{{locale}}/articles/28919-key-activities-in-sprint?utm_source=app.howwe.io&utm_medium=web&utm_campaign=sprint_create_activity_drawer"
              linkText={t('CreateMitemDrawer.helpLinks.sprintKeyActivities')}
            />
          </div>
          <div className="pb">
            <LearnMoreLink
              urlTemplate="https://help.howwe.io/{{locale}}/articles/28918-sprint-rules-and-committing-to-a-sprint?utm_source=app.howwe.io&utm_medium=web&utm_campaign=sprint_create_activity_drawer"
              linkText={t('CreateMitemDrawer.helpLinks.sprintRules')}
            />
          </div>
          <div className="pb">
            <LearnMoreLink
              urlTemplate="https://help.howwe.io/{{locale}}/articles/28913-howwe-video-guide-acceleration-meeting?utm_source=app.howwe.io&utm_medium=web&utm_campaign=sprint_create_activity_drawer"
              linkText={t(
                'CreateMitemDrawer.helpLinks.accelerationMeetingWithSprints'
              )}
            />
          </div>
          <div className="pb">
            <LearnMoreLink
              urlTemplate="https://help.howwe.io/{{locale}}/articles/28907-howwe-video-guide-closing-and-starting-sprints"
              linkText={t('CreateMitemDrawer.helpLinks.startAndCloseSprints')}
            />
          </div>
        </>
      )}
      {error && <ErrorAlert error={error} />}
    </Drawer>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CREATE_MITEM_DRAWER_MITEM = gql`
  fragment CreateMitemDrawer_Mitem on Mitem {
    id
    name
    archived
    archivedAt
    completed
    completedAt
    deadline
    definitionOfDone
    noMigAssociation
    milestone
    supportsMilestones {
      id
      domainId {
        itemId
      }
    }
    supportsInitiatives2 {
      id
      data {
        tag {
          title
          iconId
          colorCode
        }
        tag {
          title
          iconId
          colorCode
        }
        metadata {
          completedAt
          archived
        }
      }
    }
    status
    tags {
      id
      name
      teamId
      active
      backgroundColor
      createdAt
    }
    owner {
      id
      email
      name
      displayName
      archivedAt
    }
    supportedMigs {
      id
      name
      domainId {
        itemId
        teamId
      }
    }
    teamId
  }
`;

export const CREATE_MITEM_V2 = gql`
  mutation createSKA($teamId: ID!, $sprintKeyActivity: SprintKaInput!) {
    createSprintKeyActivity(
      teamId: $teamId
      sprintKeyActivity: $sprintKeyActivity
    ) {
      ...CreateMitemDrawer_Mitem
    }
  }
`;
