import { Skeleton, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { SectionHeader } from '../../../components/SectionHeader';

export const TeamMemberTableSkeleton = () => {
  const { t } = useTranslation();

  const columns: ColumnProps[] = [
    {
      title: t('common.name'),

      dataIndex: ['user', 'name'],
      defaultSortOrder: 'ascend',
      key: 'name',
      render: () => {
        return (
          <div>
            <Skeleton />
          </div>
        );
      },
    },
    {
      title: t('common.email'),
      key: 'email',
      render: () => {
        return (
          <div>
            <Skeleton />
          </div>
        );
      },
    },
    {
      title: t('TeamMemberTable.teamRole'),
      key: 'teamAssociations',
      render: () => {
        return (
          <div>
            <Skeleton />
          </div>
        );
      },
    },
    {
      title: t('common.actions'),
      key: 'actions',
      width: '25%',
      render: () => {
        return (
          <div>
            <Skeleton.Button />
            <Skeleton.Button />
          </div>
        );
      },
    },
  ];

  return (
    <SectionHeader title={t('TeamMemberTable.title')}>
      <Table
        bordered={false}
        loading={true}
        size="middle"
        className="showScrollBar"
        dataSource={[]}
        columns={columns}
        pagination={false}
        rowKey={(item) => item.user.id}
        rowClassName={() => 'border-none'}
      />
    </SectionHeader>
  );
};
