import { Modal } from 'antd';
import { useState } from 'react';

import { Btn } from '../../../../../components/Button';
import { MilestoneCardModalContent } from '../../../../company/initiatives/initiativeDetails/progressSection/components/milestoneTable/components/milestoneActions/components/MilestoneCardModalContent';
import { ManageSearchIcon } from '../../../../../icons/ManageSearch';

interface Props {
  teamId: string;
  milestoneId: string;
  tenantId: string;
}

export const SprintMilestoneTableActions = ({
  teamId,
  milestoneId,
  tenantId,
}: Props) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <>
      <Modal
        open={showDetails}
        footer={null}
        width={800}
        onCancel={() => setShowDetails(false)}
      >
        <MilestoneCardModalContent
          milestoneId={milestoneId}
          tenantId={tenantId}
          teamId={teamId}
          onCloseModal={() => setShowDetails(false)}
        />
      </Modal>
      <Btn
        className="txt--secondary"
        onClick={() => setShowDetails(true)}
        type="text"
        icon={<ManageSearchIcon style={{ fontSize: 20 }} />}
      />
    </>
  );
};
