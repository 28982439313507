import { gql, useSuspenseQuery } from '@apollo/client';
import { useTeamPermissions } from '../../../../usePermissions';
import {
  Action,
  SprintKeyActivityDetails_MitemFragment,
  SprintKeyActivityDetails_QueryDocument,
} from '../../../../generated/graphql';
import { SkaDetailsContent } from '../../../team/sprint/planning/components/mitemPlanningBoard/skaDetails/SkaDetailsContent';
import { Spin, Tabs } from 'antd';
import { MitemHistory } from '../../../team/sprint/common/components/MitemHistory';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Btn } from '../../../../components/Button';
import { CloseOutlined } from '@ant-design/icons';
import './SprintKeyActivityDetails.less';
import { StatusTag } from '../../../../components/StatusTag';
import { SprintKeyActivityDetailsSkeleton } from './SprintKeyActivityDetails.skeleton';
import { SprintKeyActivityReportForm } from '../../../../components/keyActivityDetailsAndReportModal/SprintKeyActivityReportForm';
import { useMe } from '../../../../hooks/useMe';
import { useEffect, useState } from 'react';

interface Props {
  sprintKeyActivity: SprintKeyActivityDetails_MitemFragment;
  onClose: () => void;
}

enum TabKeys {
  Details = 'details',
  History = 'history',
  Report = 'report',
}
export const SprintKeyActivityDetails = ({
  sprintKeyActivity,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<TabKeys>(TabKeys.Details);

  const me = useMe();
  const { isAllowed, loading } = useTeamPermissions({
    teamId: sprintKeyActivity.teamId,
    requestedAction: {
      action: Action.READ,
      resource: 'sprintKA',
    },
  });

  const userIsOwner = sprintKeyActivity.owner.id === me?.data?.me.id;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const activeTabParam = params.get('activeTab');

    // Map the query param to tab keys
    if (activeTabParam === 'details') {
      setActiveTab(TabKeys.Details);
    } else if (activeTabParam === 'history') {
      setActiveTab(TabKeys.History);
    } else if (activeTabParam === 'report' && userIsOwner) {
      setActiveTab(TabKeys.Report);
    } else {
      setActiveTab(TabKeys.Details); // Default tab key
    }
  }, [userIsOwner]);

  return (
    <div
      className={cx(
        'SprintKeyActivityDetails',
        `SprintKeyActivityDetails--${sprintKeyActivity.status}`
      )}
    >
      <Spin spinning={loading}>
        <div className="SprintKeyActivityDetails__header">
          <div className="SprintKeyActivityDetails__statusTagWrapper">
            <StatusTag status={sprintKeyActivity.status} />
          </div>

          <Btn
            shape="circle"
            type="text"
            onClick={onClose}
            className="SprintKeyActivityDetails__closeButton"
            icon={<CloseOutlined />}
          />
        </div>

        <Tabs
          size="small"
          tabBarStyle={{ fontSize: 12 }}
          activeKey={activeTab}
          onChange={(key) => setActiveTab(key as TabKeys)}
          items={[
            {
              key: TabKeys.Details,
              label: t('common.details'),
              children: <SkaDetailsContent fullSKa={sprintKeyActivity} />,
            },
            {
              key: TabKeys.History,
              label: t('common.history'),
              disabled: loading || !isAllowed,
              children: (
                <div className="SkaDetails__tabContent pl--xs">
                  <MitemHistory
                    teamId={sprintKeyActivity.teamId}
                    mitemId={sprintKeyActivity.id}
                  />
                </div>
              ),
            },
            // Conditionally include the "report" tab
            ...(userIsOwner
              ? [
                  {
                    key: TabKeys.Report,
                    label: t('SprintKeyActivityDetails.report'),
                    children: (
                      <div className="SkaDetails__tabContent pl--xs">
                        <SprintKeyActivityReportForm
                          keyActivity={sprintKeyActivity}
                          onCompleted={() => setActiveTab(TabKeys.History)}
                        />
                      </div>
                    ),
                  },
                ]
              : []),
          ]}
        />
      </Spin>
    </div>
  );
};

SprintKeyActivityDetails.skeleton = SprintKeyActivityDetailsSkeleton;

interface WrapperProps {
  skaId: string;
  teamId: string;
  onClose: () => void;
}

export const SprintKeyActivityDetailsWrapper = ({
  skaId,
  teamId,
  onClose,
}: WrapperProps) => {
  const { data } = useSuspenseQuery(SprintKeyActivityDetails_QueryDocument, {
    variables: { skaId, teamId },
  });

  return (
    <SprintKeyActivityDetails
      sprintKeyActivity={data.mitem}
      onClose={onClose}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_SPRINT_KEY_ACTIVITY = gql`
  query SprintKeyActivityDetails_Query($teamId: ID!, $skaId: ID!) {
    mitem(teamId: $teamId, mitemId: $skaId) {
      ...SprintKeyActivityDetails_Mitem
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_KEY_ACTIVITY_DETAILS_FRAGMENT = gql`
  fragment SprintKeyActivityDetails_Mitem on Mitem {
    id
    name
    teamId
    owner {
      id
    }
    status
    ...SkaDetailsContent_Mitem
    ...SprintKeyActivityReportForm__Mitem
  }
`;
