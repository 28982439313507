import { gql, MutationFunctionOptions, useMutation } from '@apollo/client';
import { Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { Btn } from '../../../../Button';
import { MinusCircleOutlined } from '@ant-design/icons';
import {
  RemoveTeamLinkMutationDocument,
  TeamLinkTypes,
} from '../../../../../generated/graphql';
import { showNotification } from '../../../../../services/fetchNotificationProperties';

interface Props {
  /** Entity to unlink from team */
  domainId: {
    tenantId: string;
    itemId: string;
  };
  linkType: TeamLinkTypes;
  /** Team to unlink entity from */
  teamId: string;
  mutationOptions?: Omit<MutationFunctionOptions, 'variables'>;
  onSuccess?: () => void;
}

export const RemoveTeamLink = ({
  teamId,
  domainId,
  linkType,
  mutationOptions,
  onSuccess,
}: Props) => {
  const { t } = useTranslation();
  const [UnassignTeamFromEntity, { loading }] = useMutation(
    RemoveTeamLinkMutationDocument
  );

  const handleUnenroll = () => {
    UnassignTeamFromEntity({
      variables: {
        teamId,
        domainId,
        type: linkType,
      },

      onError: () => {
        showNotification('error', {
          message: t('RemoveTeamLink.error'),
        });
      },
      onCompleted: () => {
        showNotification('success', {
          message: t('RemoveTeamLink.success'),
        });
        onSuccess?.();
      },

      ...mutationOptions,
    });
  };

  return (
    <Popconfirm
      title={t('RemoveTeamLink.confirmation')}
      onConfirm={handleUnenroll}
      okText={t('common.yes')}
      cancelText={t('common.no')}
    >
      <Btn
        type="link"
        loading={loading}
        disabled={loading}
        icon={<MinusCircleOutlined />}
      >
        {t('RemoveTeamLink.button')}
      </Btn>
    </Popconfirm>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UNASSIGN_TEAM_FROM = gql`
  mutation removeTeamLinkMutation(
    $teamId: ID!
    $domainId: TeamLinkDomainIdInput!
    $type: TeamLinkTypes!
  ) {
    removeLinkToTeam(teamId: $teamId, domainId: $domainId, type: $type) {
      id
    }
  }
`;
