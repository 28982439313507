import { useTranslation } from 'react-i18next';
import { Dot } from '../../../../../../../../../../components/Dot';
interface Props {
  colors: {
    committed: string;
    fulfilled: string;
  };
}

const SmallLegends = ({ colors }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="flx">
      <div className="flx flx--ai-center mr--l">
        <Dot size={9} color={colors.committed} className="mr--xs" />
        <h5>{t('common.commitment_one')}</h5>
      </div>

      <div className="flx flx--ai-center">
        <Dot size={9} color={colors.fulfilled} className="mr--xs" />
        <h5>{t('SmallLegends.achievement')}</h5>
      </div>
    </div>
  );
};

export default SmallLegends;
