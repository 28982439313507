import {
  Action,
  TeamResourceType,
} from '../../../../../../../../../../generated/graphql';
import { PermittedTeamSelectorForAction } from '../../../../../../../../strategicPlan/components/PermittedTeamSelectorForAction';
import { PlusOutlined } from '@ant-design/icons';
import { Btn } from '../../../../../../../../../../components/Button';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';

interface Props {
  milestoneName: string;
}

export const ManageMilestoneTeamsSkeleton = ({ milestoneName }: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      <h3 className="mb--s">{t('ManageMilestoneTeams.modalTitle')}</h3>

      <h5 className="mb--xl">{milestoneName}</h5>
      <div className="flx mb--xl">
        <PermittedTeamSelectorForAction
          disabled={true}
          alreadySelectedTeams={[]}
          teamResourceType={TeamResourceType.TEAM_LINKS}
          action={Action.CREATE}
          className="flx--1 mr"
          alreadySelectedText={t('ManageMilestoneTeams.alreadySelected')}
        />
        <Btn icon={<PlusOutlined />} disabled={true} type="primary">
          {t('ManageMilestoneTeams.assignTeamButton')}
        </Btn>
      </div>
      <h3 className="mb"> {t('ManageMilestoneTeams.assignedTableHeader')}</h3>
      <Table
        loading={true}
        size="middle"
        pagination={false}
        dataSource={[]}
        columns={[
          {
            title: t('common.name'),
            dataIndex: 'team',
            key: 'team',
            render: (team) => team.name,
          },
          {
            defaultSortOrder: 'ascend',
            key: 'actions',
            width: 150,
          },
        ]}
      />
    </div>
  );
};
