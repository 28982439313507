import {
  MilestoneStatus,
  MitemStatus,
  SprintMitemTable_MilestoneFragment,
  SprintMitemTable_SkaTimeStatusFragment,
} from '../../../../../generated/graphql';
import { MitemFilterStatus } from '../components/mitemFilters/StatusFilter';
import { useQueryParams } from '../../../common/hooks/useQuery';

export const useMitemFilters = () => {
  const { queryParams, setParam } = useQueryParams();

  let mitemStatusFilter = queryParams.get('status') ?? 'ALL';

  const setMitemStatusFilter = (status: MitemFilterStatus) => {
    setParam('status', status);
  };

  const migIdFilter = queryParams.get('migId') ?? 'ALL';

  const setMigIdFilter = (migId: string) => {
    setParam('migId', migId);
  };

  const mitemOwnerIdFilter = queryParams.get('ownerId') ?? 'ALL';

  const setMitemOwnerIdFilter = (ownerId: string) => {
    setParam('ownerId', ownerId);
  };

  const setMilestoneIdFilter = (milestoneId: string) => {
    setParam('milestoneId', milestoneId);
  };

  return {
    currentFilters: {
      status: mitemStatusFilter as MitemFilterStatus,
      ownerId: mitemOwnerIdFilter,
      migId: migIdFilter,
      milestoneId: queryParams.get('milestoneId') ?? 'ALL',
    },
    setFilter: {
      mitemStatus: setMitemStatusFilter,
      milestoneId: setMilestoneIdFilter,
      mitemOwnerId: setMitemOwnerIdFilter,
      migId: setMigIdFilter,
    },
  };
};

export type MitemFilters = ReturnType<typeof useMitemFilters>;

export const ownerFilterCompare = (
  ownerId: string,
  ownerIdToMatch?: string
) => {
  return (
    ownerIdToMatch == null ||
    ownerIdToMatch === 'ALL' ||
    ownerId === ownerIdToMatch
  );
};

export const migFilterCompare = (
  migIds: string[] | null | undefined,
  migIdToMatch?: string
) => {
  return (
    migIdToMatch == null ||
    migIdToMatch === 'ALL' ||
    migIds?.includes(migIdToMatch) ||
    (migIds == null && migIdToMatch === 'other')
  );
};

export const milestoneFilterCompare = (
  supportMilestones: { id: string }[] | null | undefined,
  milestoneIdToMatch?: string | null
) => {
  return (
    milestoneIdToMatch == null ||
    milestoneIdToMatch === 'ALL' ||
    supportMilestones?.some((m) => m.id === milestoneIdToMatch)
  );
};

export const statusFilterCompare = (
  mitemStatus: MitemStatus | MilestoneStatus,
  statusToMatch?: MitemFilterStatus
) => {
  const mitemStatusToCheck =
    mitemStatus === MilestoneStatus.UPCOMING ? 'PLANNED' : mitemStatus;
  return (
    statusToMatch == null ||
    statusToMatch === 'ALL' ||
    mitemStatusToCheck === statusToMatch
  );
};

export const sprintKeyActivityHasTagMatch = (
  record:
    | SprintMitemTable_SkaTimeStatusFragment
    | SprintMitemTable_MilestoneFragment,
  andOrChoice: number,
  tagsToMatch?: string[]
): boolean => {
  let allTagsIds = [] as string[];
  if (record.__typename === 'SkaTimeStatus') {
    const mitemTagsIds = record?.sprintKeyActivity?.tags.map((t) => t.id) ?? [];

    const initiativeTagsIds =
      record?.sprintKeyActivity?.supportsInitiatives2?.map((t) => t.id) ?? [];

    allTagsIds = [...mitemTagsIds, ...initiativeTagsIds];
  }

  if (record.__typename === 'MilestoneWithLinks') {
    allTagsIds =
      record.metadata.supportsInitiatives.map((si) => si.data.id) ?? [];
  }

  if (!tagsToMatch || tagsToMatch.length < 1) return true;
  // AND
  if (andOrChoice === 0)
    return tagsToMatch.every((tid) => allTagsIds?.includes(tid));
  // OR
  if (andOrChoice === 1)
    return tagsToMatch.some((tid) => allTagsIds?.includes(tid));

  return false;
};
