import { Form } from 'antd';
import { Btn } from '../../../../../../components/Button';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { atLeastOne } from '../../../../../../services/error-parser/helpers';
import { gql, useQuery } from '@apollo/client';
import { MitemFormMilestonesForTeamDocument } from '../../../../../../generated/graphql';
import { TeamMilestoneSelector } from './TeamMilestoneSelector';

type SelectableMilestone = {
  id: string;
  domainId: {
    itemId: string;
    tenantId: string;
  };
  name: string;
  deadlineAt: string;
  metadata: {
    completedAt: string | null;
    archived: boolean;
  };
};

interface Props {
  teamId: string;
  selectedMilestoneIds: string[];
  initialMilestones?: SelectableMilestone[];
}

export const MitemFormMilestoneFormItem = ({
  teamId,
  selectedMilestoneIds,
  initialMilestones,
}: Props) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(MitemFormMilestonesForTeamDocument, {
    variables: { teamId },
  });

  // Fetch milestones and filter active ones
  let teamMilestones =
    data?.milestonesWithLinksForTeam.milestones.filter(
      (ti) => ti.metadata.archived === false && ti.metadata.completedAt == null
    ) ?? [];

  // Merge initial milestones uniquely
  const milestoneMap = new Map<string, SelectableMilestone>();
  initialMilestones?.forEach((milestone) =>
    milestoneMap.set(milestone.id, milestone)
  );
  teamMilestones.forEach((milestone) =>
    milestoneMap.set(milestone.id, milestone)
  );
  const selectableTeamMilestones = Array.from(milestoneMap.values());

  const teamHasMilestones = atLeastOne(selectableTeamMilestones);

  return (
    <Form.Item label={t('MitemFormMilestoneFormItem.milestones')}>
      <Form.List name="supportsMilestoneIds">
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map(({ key, ...field }, index) => (
                <div className="flx" key={key + index}>
                  <Form.Item className="flx--1" {...field}>
                    <TeamMilestoneSelector
                      milestones={selectableTeamMilestones ?? []}
                      selectedMilestoneIds={selectedMilestoneIds}
                      className={
                        index > 0 ? 'SprintKaForm__initiativeSelector' : ''
                      }
                      loading={loading}
                      disabled={!teamHasMilestones}
                    />
                  </Form.Item>
                  {index > 0 && (
                    <Btn
                      type="text"
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                  )}
                </div>
              ))}
              <Form.Item>
                <Btn
                  type="link"
                  style={{ padding: 0 }}
                  icon={<PlusOutlined />}
                  disabled={
                    !teamHasMilestones ||
                    teamMilestones?.length === selectedMilestoneIds?.length
                  }
                  onClick={() => {
                    add();
                  }}
                >
                  {t('MitemFormMilestoneFormItem.addMilestone')}
                </Btn>
              </Form.Item>
            </div>
          );
        }}
      </Form.List>
    </Form.Item>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MITEM_FORM_MILESTONES_FOR_TEAM = gql`
  query mitemFormMilestonesForTeam($teamId: ID!) {
    milestonesWithLinksForTeam(teamId: $teamId) {
      milestones {
        id
        domainId {
          itemId
          tenantId
        }
        deadlineAt
        name
        metadata {
          archived
          completedAt
          status
        }
      }
    }
  }
`;
