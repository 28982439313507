import { Line, LineChart, ResponsiveContainer } from 'recharts';
import { Colors } from '../../../appPages/componentLibrary/Colors';
import { useTranslation } from 'react-i18next';
import { Dot } from '../../Dot';
interface Props {
  periodData?: {
    date: string;
    completed?: number | null;
    total?: number | null;
  }[];
  width?: number;
  height?: number;
}

export const InitiativePreviewGraph = ({ periodData, height = 50 }: Props) => {
  const { t } = useTranslation();
  if (periodData == null || periodData.length === 0) {
    return null;
  }

  return (
    <div>
      <ResponsiveContainer debounce={300} height={height} width="100%">
        <LineChart height={height} data={periodData}>
          <Line
            dataKey="total"
            stroke={Colors.Howwe.MAIN_ORANGE}
            strokeWidth={2}
            isAnimationActive={false}
            dot={false}
          />
          <Line
            dataKey="completed"
            stroke={Colors.Action.BLUE}
            strokeWidth={2}
            isAnimationActive={false}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
      <div className="txt--secondary">
        <div className="flx">
          <div className="flx flx--ai-center mr--l">
            <Dot size={6} color={Colors.Action.BLUE} className="mr--xs" />
            <h6>{t('common.completed')}</h6>
          </div>

          <div className="flx flx--ai-center">
            <Dot size={6} color={Colors.Howwe.MAIN_ORANGE} className="mr--xs" />
            <h6> {t('common.total')}</h6>
          </div>
        </div>
      </div>
    </div>
  );
};
