import { Divider, Tabs, TabsProps } from 'antd';
import { MilestoneDetails } from './MilestoneDetails';
import { MilestoneCardActivityTab } from './MilestoneCardActivityTab';
import { useTranslation } from 'react-i18next';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useRouteMatch } from 'react-router-dom';
import { usePermissionForTeams } from '../../../../../../../../../../hooks/usePermissionForTeams';
import {
  Action,
  SprintSummaryMilestoneQueryDocument,
  TeamLinkTypes,
  TeamResourceType,
} from '../../../../../../../../../../generated/graphql';
import { RemoveTeamLink } from '../../../../../../../../../../components/initiatives/InitiativeActionsDropdown/components/manageInitiativeTeamsModal/RemoveTeamLink';

interface Props {
  milestoneId: string;
  tenantId: string;
  teamId?: string;
  onCloseModal?: () => void;
}

export const MilestoneCardModalContent = ({
  milestoneId,
  teamId,
  tenantId,
  onCloseModal,
}: Props) => {
  const { t } = useTranslation();
  const match = useRouteMatch<{ teamId: string }>('/team/:teamId');
  const { data: teamLinkPermissions } = usePermissionForTeams(
    TeamResourceType.TEAM_LINKS,
    Action.UPDATE
  );

  teamId = teamId || match?.params.teamId;
  const allowedToUnassignMilestone =
    teamLinkPermissions?.permittedToPerformActionForTeam?.permittedResources?.some(
      (resource) => resource.teamId === teamId
    );

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('MilestoneCardModalContent.generalInfo'),
      children: (
        <ErrorBoundary FallbackComponent={MilestoneDetails.Error}>
          <Suspense fallback={<MilestoneDetails.Skeleton />}>
            <MilestoneDetails milestoneId={milestoneId} />
          </Suspense>
        </ErrorBoundary>
      ),
    },
    {
      key: '2',
      label: t('MilestoneCardModalContent.activitiesConnected'),
      children: (
        <ErrorBoundary FallbackComponent={MilestoneCardActivityTab.Error}>
          <Suspense
            fallback={<MilestoneCardActivityTab.Skeleton teamId={teamId} />}
          >
            <MilestoneCardActivityTab
              milestoneId={milestoneId}
              teamId={teamId}
            />
          </Suspense>
        </ErrorBoundary>
      ),
    },
  ];

  return (
    <>
      <Tabs defaultActiveKey="1" items={items} />
      <Divider />

      {teamId && allowedToUnassignMilestone && (
        <div className="flx">
          <div className="ml--auto">
            <RemoveTeamLink
              domainId={{
                itemId: milestoneId,
                tenantId: tenantId,
              }}
              teamId={teamId}
              linkType={TeamLinkTypes.MILESTONE}
              onSuccess={() => onCloseModal?.()}
              mutationOptions={{
                refetchQueries: [
                  {
                    query: SprintSummaryMilestoneQueryDocument,
                    variables: { teamId: teamId },
                  },
                ],
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
