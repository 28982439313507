import { Rectangle, Layer } from 'recharts';
import { InitiativeIcon } from '../../../../../components/initiative/InitiativeIcon';
import { getInitiativeBorderColor } from '../../initiativeBorderColor';

interface Props {
  x: number;
  y: number;
  width: number;
  height: number;
  index: number;
  payload: {
    name: string;
    color: string;
    value: number;
    iconId?: string;
  };
}

// We have to type the props as 'any' since we don't pass the props directly, they're passed by the library.
// If we use the Props interface directly for the props then we'll get an error at the callsite since it looks like we're missing props
export default function Node(props: any) {
  const { index, payload } = props as Props;
  const textPadding = 10; // Added padding for text

  const height = isNaN(props.height) ? 0 : props.height;
  const width = isNaN(props.width) ? 0 : props.width;
  const x = isNaN(props.x) ? 0 : props.x;
  const y = isNaN(props.y) ? 0 : props.y;

  if (payload.name !== 'Revenue' && payload.name !== 'Profit') {
    const iconSize = 20;
    return (
      <Layer key={`CustomNode${index}`}>
        <Rectangle
          x={x}
          y={y}
          width={width}
          height={height}
          fill={payload.color}
          fillOpacity="1"
          stroke={getInitiativeBorderColor(payload.color)}
          radius={3}
        />
        {payload.iconId && (
          <foreignObject
            style={{
              overflow: 'visible',
              fontSize: iconSize,
            }}
            x={x + width + textPadding}
            y={y + height / 2 - iconSize / 2}
          >
            <span style={{ display: 'flex' }}>
              <InitiativeIcon iconId={payload.iconId} />
            </span>
          </foreignObject>
        )}
      </Layer>
    );
  }

  const labelSize = 14;
  const rowSpace = 5;
  const label1Y = y + height / 2 - rowSpace / 2;
  const label2Y = label1Y + labelSize + rowSpace;

  return (
    <Layer key={`CustomNode${index}`}>
      <Rectangle
        x={x}
        y={y}
        width={width}
        height={height}
        fill={payload.color}
        stroke={getInitiativeBorderColor(payload.color)}
        strokeWidth={1}
        fillOpacity="1"
        radius={3}
      />

      <text
        textAnchor={'start'}
        x={x + width + textPadding}
        y={label1Y - 7}
        fontSize={labelSize}
        fontWeight={700}
        stroke="#333"
        strokeOpacity="0.2"
        dominantBaseline="middle"
      >
        {payload.name}
      </text>
      <text
        textAnchor={'start'}
        fontSize={labelSize}
        fontWeight={700}
        x={x + width + textPadding}
        y={label2Y}
        stroke="#333"
        strokeOpacity="0.5"
      >
        {payload.value}
      </text>
    </Layer>
  );
}
