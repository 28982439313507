import { gql, useMutation } from '@apollo/client';
import { Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { DisplayName } from '../../../components/DisplayName';
import { ErrorAlert } from '../../../components/ErrorAlert';
import {
  GetTeamMembersV2HookDocument,
  RemoveTeamMemberDocument,
  UnassignUserFromTeamModalWithConfirmButton__User2Fragment,
} from '../../../generated/graphql';
import { showNotification } from '../../../services/fetchNotificationProperties';
import { Btn } from '../../../components/Button';

interface Props {
  teamId: string;
  user: UnassignUserFromTeamModalWithConfirmButton__User2Fragment;
  rev: string;
  tenantId?: string;
}

export const UnassignUserFromTeamModalWithConfirmButton = ({
  user,
  teamId,
  tenantId,
  rev,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const [unassignUserFromTeam, unassignRequest] = useMutation(
    RemoveTeamMemberDocument,
    {
      variables: {
        teamId: teamId,
        userId: user.domainId.itemId,
        rev,
        tenantId,
      },
      refetchQueries: () => [
        {
          query: GetTeamMembersV2HookDocument,
          variables: { teamId: teamId, tenantId },
        },
      ],
    }
  );

  return (
    <Popconfirm
      title={
        <>
          <header>
            <b>{t('UnassignUserFromTeamModalWithConfirmButton.popTitle')}</b>
          </header>
          <br />
          <main>
            <p>{t('UnassignUserFromTeamModalWithConfirmButton.popText1')}</p>
            <p>
              <b>{<DisplayName user={user}></DisplayName>} </b>
            </p>
            <p>{t('UnassignUserFromTeamModalWithConfirmButton.popText2')}</p>
          </main>
          <br />
        </>
      }
      okText={t(
        'UnassignUserFromTeamModalWithConfirmButton.removeButtonOkText'
      )}
      onConfirm={() => {
        unassignUserFromTeam({
          variables: {
            teamId: teamId,
            userId: user.domainId.itemId,
            tenantId,
            rev,
          },
        }).then(() => {
          showNotification('success', {
            message: (
              <span>
                {t('UnassignUserFromTeamModalWithConfirmButton.successText')}{' '}
                <strong>
                  <DisplayName user={user} />
                </strong>
                .
              </span>
            ),
          });
        });
      }}
    >
      <Btn type="text" onClick={() => null}>
        {t('UnassignUserFromTeamModalWithConfirmButton.removeButtonOkText')}
      </Btn>
      <ErrorAlert error={unassignRequest.error} />
    </Popconfirm>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UNASSIGN_FROM_TEAM = gql`
  mutation removeTeamMember(
    $teamId: ID!
    $userId: ID!
    $rev: String!
    $tenantId: ID
  ) {
    removeTeamMember(
      teamId: $teamId
      userId: $userId
      rev: $rev
      tenantId: $tenantId
    ) {
      id
      rev
      members {
        roles
        user {
          id
          data {
            id
            domainId {
              itemId
            }
            name
            displayName
            initials
            email
          }
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UNASSIGN_USER_FROM_TEAM_FRAGMENT = gql`
  fragment UnassignUserFromTeamModalWithConfirmButton__User2 on User2 {
    id
    domainId {
      itemId
    }
    name
    email
    displayName
    initials
  }
`;
