import React from 'react';
import './Dot.less';

interface DotProps {
  color: string;
  size?: number;
  className?: string;
}

export const Dot: React.FC<DotProps> = ({ color, size = 8, className }) => {
  return (
    <span
      className={`Dot ${className || ''}`}
      style={{
        backgroundColor: color,
        width: size,
        height: size,
      }}
    />
  );
};
