import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as d3 from 'd3';
import { gql } from '@apollo/client';
import {
  InitiativesBubbleChart__InitiativeDetailedReportResponseFragment,
  InitiativesBubbleChart__InitiativeLightFragment,
} from '../../../../generated/graphql';
import { InitiativeIcon } from '../../../../components/initiative/InitiativeIcon';
import { getInitiativeBorderColor } from '../initiativeBorderColor';

// Define the types for the data
interface DataPoint extends d3.SimulationNodeDatum {
  value: number;
  radius: number; // Add radius to the data
  iconId: string;
  color: string;
}

interface Props {
  initiativesDetails: InitiativesBubbleChart__InitiativeDetailedReportResponseFragment[];
  initiatives: InitiativesBubbleChart__InitiativeLightFragment[];
}

export const InitiativesBubbleChart = ({
  initiativesDetails,
  initiatives,
}: Props) => {
  const { t } = useTranslation();
  const width = 400;
  const height = 400;

  // Scale for radius based on value
  const radiusScale = useMemo(
    () =>
      d3
        .scaleSqrt()
        .domain([
          0,
          Math.max(...initiativesDetails.map((d) => d.teamStats.length)),
        ])
        .range([10, 50]),
    [initiativesDetails]
  );

  // Prepare bubble data
  const bubbles: DataPoint[] = useMemo(() => {
    return initiativesDetails.map((d) => {
      const initiative = initiatives.find(
        (i) => i.domainId.itemId === d.domainId.itemId
      );
      return {
        ...d,
        iconId: initiative?.tag.iconId ?? '',
        value: d.teamStats.length,
        color: initiative?.tag.colorCode ?? '#8884d8',
        radius: radiusScale(d.teamStats.length),
      };
    });
  }, [initiativesDetails, initiatives, radiusScale]);

  // Get positions from force simulation
  const positions = useForceSimulation(bubbles, width, height);

  const iconSize = 20;
  return (
    <div>
      <h3>{t('InitiativesBubbleChart.teamEngagements')}</h3>
      <div className="txt--secondary mb--l">
        {t('InitiativesBubbleChart.chartDescription')}
      </div>

      <svg width={width} height={height} style={{ backgroundColor: '#fff' }}>
        {positions.map((bubble, i) => (
          <g key={i} transform={`translate(${bubble.x},${bubble.y})`}>
            <circle
              r={bubble.radius}
              fill={bubble.color}
              opacity={0.7}
              stroke={getInitiativeBorderColor(bubble.color)}
              strokeWidth={2}
            />
            <foreignObject
              style={{
                overflow: 'visible',
                fontSize: iconSize,
                height: bubble.radius * 2,
                width: bubble.radius * 2,
              }}
              x={-bubble.radius}
              y={-bubble.radius}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <div
                  style={{
                    whiteSpace: 'nowrap',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <InitiativeIcon iconId={bubble.iconId} />
                  </div>
                  <div style={{ marginLeft: 4 }}>{bubble.value}</div>
                </div>
              </div>
            </foreignObject>
          </g>
        ))}
      </svg>
    </div>
  );
};

const useForceSimulation = (
  nodes: DataPoint[],
  width: number,
  height: number
) => {
  const [positions, setPositions] = useState<DataPoint[]>(nodes);

  useEffect(() => {
    const simulation = d3
      .forceSimulation(nodes)
      .force('center', d3.forceCenter(width / 2, height / 2))
      .force(
        'collide',
        d3
          .forceCollide()
          .radius((d) => (d as DataPoint).radius)
          .strength(1)
      )
      .force('charge', d3.forceManyBody().strength(-2))
      .on('tick', () => {
        setPositions([...nodes]);
      });

    return () => {
      simulation.stop();
    };
  }, [width, height, nodes]);

  return positions;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_HEADER_SECTION__INITIATIVE_DETAILED_REPORT_RESPONSE = gql`
  fragment InitiativesBubbleChart__InitiativeDetailedReportResponse on InitiativeDetailedReportResponse {
    id
    domainId {
      itemId
      tenantId
    }
    teamStats {
      team {
        id
        name
        domainId {
          itemId
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_BUBBLE_CHART = gql`
  fragment InitiativesBubbleChart__InitiativeLight on InitiativeLight {
    id
    name

    domainId {
      itemId
      tenantId
    }
    tag {
      iconId
      colorCode
      title
    }
    metadata {
      status
    }
  }
`;
