import { Alert, Input, Modal, Form, Select } from 'antd';
import { useMutation, useQuery, gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CopyText } from '../../../components/CopyText';
import {
  GetCompanyLocaleDocument,
  CreateUserToBeAddedToTeamDocument,
  AssignMemberRoleToUserDocument,
  TeamMemberRoles,
} from '../../../generated/graphql';
import { availableLanguages } from '../../../languages';
import { ErrorAlert } from '../../../components/ErrorAlert';
import { showNotification } from '../../../services/fetchNotificationProperties';
import { TeamRolesFormItem } from './TeamRolesFormItem';

interface Props {
  teamId: string;
  value?: string;
  onClose: () => void;
  onComplete: () => void;
  tenantId?: string;
  rev: string;
}

export const CreateTeamMemberModal = (props: Props) => {
  const { t } = useTranslation();
  const { teamId, onClose, onComplete } = props;
  const [form] = Form.useForm();
  const defaultLanguage = availableLanguages[0].code; // Currently english

  const companyLocaleQuery = useQuery(GetCompanyLocaleDocument, {
    fetchPolicy: 'network-only',
  });

  const [
    assignMemberRoleToUser,
    { loading: assignMemberRoleLoading, error: assignMemberRoleError },
  ] = useMutation(AssignMemberRoleToUserDocument, {
    onCompleted: () => {
      showNotification('success', {
        message: t('CreateTeamMemberModal.success'),
      });
      onComplete();
      onClose();
    },
  });

  const [
    createUserForTeam,
    { loading: createUserLoading, error: createUserError },
  ] = useMutation(CreateUserToBeAddedToTeamDocument);

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      createUserForTeam({
        variables: {
          tenantId: props.tenantId,
          user: {
            email: values.email,
            name: values.name === '' ? null : values.name,
            locale: values.locale,
            integrations: { calendarNotifications: false },
          },
        },
        onCompleted: (data) => {
          assignMemberRoleToUser({
            variables: {
              tenantId: props.tenantId,
              teamId: teamId,
              userId: data.createUser.domainId.itemId,
              roleInputs: {
                rev: props.rev,
                roles: values.teamRole ? [values.teamRole] : [],
              },
            },
          });
        },
      });
    });
  };

  if (companyLocaleQuery.loading) return null;

  const signupLink = `${window.location.origin}/signup`;

  return (
    <Modal
      title={t('CreateTeamMemberModal.title')}
      open
      onOk={handleSubmit}
      onCancel={props.onClose}
      cancelButtonProps={{
        disabled: createUserLoading || assignMemberRoleLoading,
      }}
      confirmLoading={createUserLoading || assignMemberRoleLoading}
    >
      <Alert
        className="mb"
        type="info"
        message={
          <div>
            <div className="mb">{t('CreateTeamMemberModal.infoText')}</div>
            <Link to="/signup">{signupLink}</Link>
            <span className="ml--l">
              <CopyText text={signupLink} />
            </span>
          </div>
        }
      />
      <Form
        form={form}
        data-testid="create-member-form"
        initialValues={{
          email: props.value,
          teamRole: TeamMemberRoles.KA_EDITOR,
          locale: companyLocaleQuery.data?.tenant?.locale || defaultLanguage,
        }}
        layout="vertical"
      >
        <Form.Item
          name="email"
          label={t('CreateTeamMemberModal.labelEmail')}
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
            },
            { type: 'email' },
            {
              min: 4,
              max: 256,
            },
          ]}
        >
          <Input autoComplete="off" type="email" autoFocus />
        </Form.Item>
        <Form.Item
          name="name"
          label={t('CreateTeamMemberModal.labelFullName')}
          validateTrigger="onBlur"
          rules={[
            {
              min: 5,
              max: 256,
            },
          ]}
        >
          <Input autoComplete="off" type="text" />
        </Form.Item>
        <Form.Item name="locale" label={t('CreateTeamMemberModal.language')}>
          <Select allowClear>
            {availableLanguages.map((language) => {
              return (
                <Select.Option key={language.code} value={language.code}>
                  {t(`locale.${language.code}`)}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <TeamRolesFormItem />
      </Form>
      <ErrorAlert
        error={createUserError || assignMemberRoleError}
        title={t('CreateTeamMemberModal.errorTitle')}
      />
    </Modal>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CREATE_USER_FOR_TEAM = gql`
  mutation createUserToBeAddedToTeam($tenantId: ID, $user: CreateUser2Input!) {
    createUser(tenantId: $tenantId, user: $user) {
      name
      displayName
      email
      locale
      id
      domainId {
        itemId
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ASSIGN_MEMBER_ROLE_TO_USER = gql`
  mutation assignMemberRoleToUser(
    $tenantId: ID
    $teamId: ID!
    $userId: ID!
    $roleInputs: TeamRolesInput!
  ) {
    addOrUpdateTeamMember(
      tenantId: $tenantId
      teamId: $teamId
      userId: $userId
      roleInputs: $roleInputs
    ) {
      name
      id
      rev
      members {
        roles
        user {
          id
          data {
            name
            email
          }
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TENANT_LOCALE = gql`
  query getCompanyLocale {
    tenant {
      locale
    }
  }
`;
