import { InfoCircleOutlined } from '@ant-design/icons';
import { Table, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '../../../Auth0Provider';
import { ErrorAlert } from '../../../components/ErrorAlert';
import { SectionHeader } from '../../../components/SectionHeader';
import { stringSort } from '../../../services/stringSort';
import { AssignTeamMemberRoleModal } from './AssignTeamMemberRoleModal';
import { AssignUserSearch } from './AssignUserSearch';
import { UnassignUserFromTeamModalWithConfirmButton } from './UnassignUserFromTeamModalWithConfirmButton';
import { UserAvatar } from '../../../components/UserAvatar';
import { friendlyUsername } from '../../../services/friendlyUsername';
import { Btn } from '../../../components/Button';
import { useTeamPermissions } from '../../../usePermissions';
import {
  Action,
  GetTeamMembersDocument,
  TeamMemberTable__Team2MemberFragment,
} from '../../../generated/graphql';
import { gql, useSuspenseQuery } from '@apollo/client';
import { TeamMemberTableSkeleton } from './TeamMemberTable.skeleton';

interface Props {
  teamId: string;
  tenantId?: string;
}

interface ColumnPropsWithExclude<T> extends ColumnProps<T> {
  excluded?: boolean;
}

export const TeamMemberTable = ({ teamId, tenantId }: Props) => {
  const { t } = useTranslation();
  const { user } = useAuth0();
  const { isAllowed } = useTeamPermissions({
    requestedAction: [
      {
        resource: 'team',
        action: Action.UPDATE,
      },
    ],
    teamId: teamId,
  });

  const [currentMemberId, setCurrentMemberId] = useState<string | null>(null);
  const { data, error } = useSuspenseQuery(GetTeamMembersDocument, {
    variables: { teamId: teamId },
  });
  const columns: ColumnPropsWithExclude<TeamMemberTable__Team2MemberFragment>[] =
    [
      {
        title: t('common.name'),

        dataIndex: ['user', 'name'],
        defaultSortOrder: 'ascend',
        sorter: (a, b) => stringSort(a.user.data.name, b.user.data.name),
        key: 'name',
        render: (_, record) => {
          return (
            <div>
              <UserAvatar user={record.user.data} />
              <span className="ml--l">
                {friendlyUsername(record.user.data)}
              </span>
            </div>
          );
        },
      },
      {
        title: t('common.email'),

        dataIndex: ['user', 'data', 'email'],
        defaultSortOrder: 'ascend',
        sorter: (a, b) => stringSort(a.user.data.email, b.user.data.email),
        key: 'email',
      },
      {
        title: t('TeamMemberTable.teamRole'),
        key: 'teamAssociations',
        defaultSortOrder: 'ascend',
        render: (_, record) => {
          const roles = record.roles;
          if (roles.length === 0) {
            return t('common.teamRole.member');
          }
          return roles.map((role) => (
            <div key={role}>{t(`common.teamRole.${role.toLowerCase()}`)}</div>
          ));
        },
      },
      {
        title: t('common.actions'),
        key: 'actions',
        width: '25%',
        excluded: !isAllowed,
        render: (_, member) => {
          if (user?.howwe.userId === member.user.data.domainId.itemId)
            return (
              <Tooltip
                title={
                  <div className="flx flx--column">
                    {t('TeamMemberTable.manageTeamInfo')}
                  </div>
                }
              >
                <div className="flx flx--jc-center flx--ai-center">
                  <InfoCircleOutlined className="TeamSection__InfoIcon" />
                  <span className="ml--s">
                    {t('TeamMemberTable.noActionsPossible')}
                  </span>
                </div>
              </Tooltip>
            );
          return (
            <>
              <Btn
                type="text"
                onClick={() => setCurrentMemberId(member.user.id)}
              >
                {t('TeamMemberTable.changeRole.buttonText')}
              </Btn>

              <UnassignUserFromTeamModalWithConfirmButton
                user={member.user.data}
                rev={data?.team2.rev}
                teamId={teamId}
                tenantId={tenantId}
              />
              <AssignTeamMemberRoleModal
                teamId={teamId}
                tenantId={tenantId}
                rev={data?.team2.rev}
                roles={member.roles}
                user={member.user.data}
                visible={currentMemberId === member.user.id}
                hideModal={() => setCurrentMemberId(null)}
              />
            </>
          );
        },
      },
    ];

  return (
    <SectionHeader
      title={t('TeamMemberTable.title')}
      extra={
        isAllowed &&
        data?.team2.rev && (
          <AssignUserSearch
            teamId={data.team2.domainId.itemId}
            tenantId={tenantId}
            rev={data.team2.rev}
            members={data?.team2.members ?? []}
          />
        )
      }
    >
      <ErrorAlert error={error} title={t('TeamMemberTable.fetchMemberError')} />

      <Table
        bordered={false}
        size="middle"
        className="showScrollBar"
        dataSource={data?.team2.members ?? []}
        columns={columns.filter((column) => !column.excluded)}
        pagination={false}
        rowKey={(item) => item.user.id}
        rowClassName={() => 'border-none'}
      />
    </SectionHeader>
  );
};

TeamMemberTable.Skeleton = TeamMemberTableSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TEAM_MEMBER_FRAGMENT = gql`
  fragment TeamMemberTable__Team2Member on Team2Member {
    roles
    user {
      id
      data {
        name
        email
        displayName
        initials
        ...UnassignUserFromTeamModalWithConfirmButton__User2
        ...AssignTeamMemberRoleModal__User2
      }
    }
    ...AssignUserSearch__Team2Member
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TEAM_DETAILS = gql`
  query getTeamMembers($teamId: ID!) {
    team2(teamId: $teamId) {
      archived
      name
      id
      domainId {
        tenantId
        itemId
      }
      rev
      members {
        ...TeamMemberTable__Team2Member
      }
    }
  }
  ${TEAM_MEMBER_FRAGMENT}
`;
