import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { AccelerationBoardPage } from './accelerationBoard/AccelerationBoard.page';
import { ActiveTeamsPage } from './activeTeams/ActiveTeams.page';
import { AlignmentPage } from './alignment/Alignment.page';
import { StrategicPlanRoutes } from './strategicPlan/StrategicPlan.routes';
import { PageHeader } from '../../components/PageHeader';
import { StrategicPlanAdminModal } from '../../components/initiatives/StrategicPlanAdminModal/StrategicPlanAdminModal';
import { InitiativePage } from './initiatives/Initiative.page';
import { InitiativeActionsDropdown } from '../../components/initiatives/InitiativeActionsDropdown/InitiativeActionsDropdown';
import { PermissionChecker } from '../../PermissionChecker';
import { Action } from '../../generated/graphql';
import { CreateTopInitiativeButton } from './strategicPlan/components/InitiativeSection/createTopInitiativeButton/CreateTopInitiativeButton';
import { ReportGeneratorPage } from './reportGenerator/ReportGenerator.page';
import { useReportGeneratorFeatureFlag } from '../../hooks/featureFlagHooks';

interface Props {
  tenantName?: string;
  initiativesEnabled?: boolean;
  businessPlanEnabled?: boolean;
}

export const CompanyPageRoutes = ({
  tenantName,
  initiativesEnabled,
  businessPlanEnabled,
}: Props) => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const hasReports = useReportGeneratorFeatureFlag();

  const strategicPlanRoutePath = match.path + '/strategic-plan';
  const initiativesRoutePath = match.path + '/initiatives';

  const topTabs = [
    {
      title: 'Acceleration Board',
      url: match.url + '/acceleration-board',
      dataTestId: 'acceleration-board-tab',
    },
    {
      title: t('CompanyPageRoutes.strategicPlan'),
      exclude: !businessPlanEnabled,
      url: match.url + '/strategic-plan',
      dataTestId: 'strategic-plan-tab',
    },
    {
      title: t('CompanyPageRoutes.initiatives'),
      exclude: !(initiativesEnabled || businessPlanEnabled),
      url: match.url + '/initiatives',
      dataTestId: 'initiatives-tab',
    },
    {
      title: t('CompanyPageRoutes.accelerationMeetingOverview'),
      url: match.url + '/active-teams',
      dataTestId: 'active-teams-tab',
    },
    {
      title: t('CompanyPageRoutes.alignmentTab'),
      url: match.url + '/alignment',
      dataTestId: 'alignment-tab',
    },
    {
      title: 'Report Generator',
      url: match.url + '/generate-report',
      exclude: !hasReports,
      dataTestId: 'report-tab',
    },
  ];

  return (
    <>
      <Helmet title={tenantName} />
      <PageHeader
        pageTitle={tenantName}
        menuItems={topTabs.filter((t) => t.exclude !== true)}
        actions={
          businessPlanEnabled && (
            <Switch>
              <Route path={strategicPlanRoutePath}>
                <PermissionChecker
                  resourceOwner={{
                    type: 'TenantResource',
                    requestedAction: [
                      {
                        resource: 'initiative',
                        action: Action.UPDATE,
                      },
                    ],
                  }}
                >
                  <StrategicPlanAdminModal />
                </PermissionChecker>
              </Route>
              <Route exact path={initiativesRoutePath}>
                <PermissionChecker
                  resourceOwner={{
                    type: 'TenantResource',
                    requestedAction: [
                      {
                        resource: 'initiative',
                        action: [Action.CREATE, Action.UPDATE],
                      },
                    ],
                  }}
                >
                  <CreateTopInitiativeButton />
                  <StrategicPlanAdminModal />
                </PermissionChecker>
              </Route>
              <Route path={initiativesRoutePath + '/:initiativeId'}>
                <PermissionChecker
                  resourceOwner={{
                    type: 'TenantResource',
                    requestedAction: {
                      resource: 'initiative',
                      action: [Action.CREATE, Action.UPDATE],
                    },
                  }}
                >
                  <CreateTopInitiativeButton />
                  <StrategicPlanAdminModal />
                  <InitiativeActionsDropdown />
                </PermissionChecker>
              </Route>
            </Switch>
          )
        }
      />

      <Switch>
        <Route path={match.url + '/acceleration-board'}>
          <AccelerationBoardPage />
        </Route>
        {businessPlanEnabled && (
          <Route path={strategicPlanRoutePath}>
            <StrategicPlanRoutes />
          </Route>
        )}
        <Route path={match.path + '/active-teams'}>
          <ActiveTeamsPage />
        </Route>
        <Route path={match.path + '/alignment'}>
          <AlignmentPage />
        </Route>
        {hasReports && (
          <Route path={match.path + '/generate-report'}>
            <ReportGeneratorPage />
          </Route>
        )}
        {businessPlanEnabled && (
          <Route path={initiativesRoutePath}>
            <InitiativePage />
          </Route>
        )}
      </Switch>
    </>
  );
};
