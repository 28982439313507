import { useMemo, useState } from 'react';
import './GigantishReport.less';
import dayjs, { Dayjs } from 'dayjs';
import { GigantishReportChartColumns } from './components/GigantishReportChartColumns';
import { GigantishReportChartInitiative } from './components/GigantishReportChartInitiative';
import { gql } from '@apollo/client';
import {
  DurationStatus,
  GigantishReport__TopInitiativeReportFragment,
} from '../../../../../generated/graphql';
import { GigantishReportSkeleton } from './GigantishReport.skeleton';
import {
  GigantishNode,
  gigantishTreeBuilder,
} from '../../../strategicPlan/components/Gigantish/components/GigantishTreeBuilder';
import { DatePicker } from 'antd';

type GigantishReportInitiative = {
  startAt: string | null;
  endAt: string | null;
  metadata: {
    status: DurationStatus;
    completedAt?: string | null;
    archived?: boolean;
  };
};

export type GigantishReportTreeNode = GigantishNode<
  GigantishReportInitiative,
  GigantishReportInitiative
>;

interface Props {
  isPreviewMode: boolean;
  isHidden: (initiativeId: string) => boolean;
  topInitiativeReports: GigantishReport__TopInitiativeReportFragment[];
}

export const GigantishReport = ({
  topInitiativeReports,
  isPreviewMode,
  isHidden,
}: Props) => {
  const [boundary, setBoundary] = useState<{ start: Dayjs; end: Dayjs }>({
    start: dayjs().startOf('year'),
    end: dayjs().endOf('year'),
  });

  const memoizedGigantishReportTree = useMemo(() => {
    return gigantishTreeBuilder(topInitiativeReports ?? []);
  }, [topInitiativeReports]);

  return (
    <>
      <h3>Strategic plan overview</h3>
      {!isPreviewMode && (
        <div className="flx mt--l flx--ai-center ">
          <h5 className="mr--l">Graph period to show</h5>
          <div>
            <span className="txt--secondary font-size--sm">From: </span>
            <DatePicker
              value={boundary.start}
              allowClear={false}
              className="mr"
              picker="month"
              onChange={(date) => {
                if (date) {
                  setBoundary({ ...boundary, start: date.startOf('month') });
                }
              }}
            />
            <span className="txt--secondary font-size--sm">To: </span>
            <DatePicker
              value={boundary.end}
              allowClear={false}
              picker="month"
              onChange={(date) => {
                if (date) {
                  setBoundary({ ...boundary, end: date.endOf('month') });
                }
              }}
            />
          </div>
        </div>
      )}

      <div className="GigantishReport">
        <div className="GigantishReport__chartColumns">
          <GigantishReportChartColumns boundary={boundary} />
        </div>
        <div className="GigantishReport__rows">
          {memoizedGigantishReportTree.map((initiative) => {
            return (
              <GigantishReportChartInitiative
                key={initiative.id}
                isHidden={isHidden}
                initiative={initiative}
                boundary={boundary}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

GigantishReport.skeleton = GigantishReportSkeleton;

export const daysToPercentage = (
  days: number,
  totalDaysWithinBoundary: number
) => (days / totalDaysWithinBoundary) * 100 + '%';

interface InitData {
  id: string;
  name: string;
  completed?: boolean;
  archived?: boolean;
  startAt: string;
  endAt?: string;
  revenue?: {
    potential?: number;
    current?: number;
  };
  profit?: {
    potential?: number;
    current?: number;
  };
  subInitiatives?: Array<InitData>;
}

export interface InitiativeNode extends InitData {
  subInitiatives?: Array<InitiativeNode>;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GIGANTISH_TOP_INITIATIVE_REPORT = gql`
  fragment GigantishReport__TopInitiativeReport on TopInitiativeReport2 {
    id
    initiative {
      ...GigantishReport__InitiativeLight
    }
    allSubInitiatives {
      ...GigantishReport__InitiativeLight
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GIGANTISH__INITIATIVE = gql`
  fragment GigantishReport__InitiativeLight on InitiativeLight {
    domainId {
      itemId
      tenantId
    }
    startAt
    endAt
    name
    id

    tag {
      ...gigantishReport__InitiativeTag
    }
    metadata {
      completedAt
      archived
      status
      supportsInitiatives {
        id
        domainId {
          itemId
          tenantId
        }
      }
      supportsMigs {
        id
        domainId {
          itemId
          tenantId
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GIGANTISH__INITIATIVE_TAG = gql`
  fragment gigantishReport__InitiativeTag on InitiativeTag {
    title
    iconId
    colorCode
  }
`;
