import {
  gql,
  NetworkStatus,
  useMutation,
  useSuspenseQuery,
} from '@apollo/client';
import {
  Action,
  ManageMilestoneTeamsAssignMutationDocument,
  ManageMilestoneTeamsQueryDocument,
  TeamLinkTypes,
  TeamResourceType,
} from '../../../../../../../../../../generated/graphql';
import { PermittedTeamSelectorForAction } from '../../../../../../../../strategicPlan/components/PermittedTeamSelectorForAction';
import { PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { Btn } from '../../../../../../../../../../components/Button';
import { useTenantOverride } from '../../../../../../../../../../TenantOverrideProvider';
import { useTranslation } from 'react-i18next';
import { showNotification } from '../../../../../../../../../../services/fetchNotificationProperties';
import { howweErrorParser } from '../../../../../../../../../../services/howweErrorParser';
import { Table } from 'antd';
import { RemoveTeamLink } from '../../../../../../../../../../components/initiatives/InitiativeActionsDropdown/components/manageInitiativeTeamsModal/RemoveTeamLink';
import { ManageMilestoneTeamsSkeleton } from './ManageMilestoneTeams.skeleton';

interface Props {
  milestoneId: string;
  milestoneName: string;
}

export const ManageMilestoneTeams = ({ milestoneId, milestoneName }: Props) => {
  const { t } = useTranslation();
  const { data, networkStatus } = useSuspenseQuery(
    ManageMilestoneTeamsQueryDocument,
    {
      variables: { milestoneId },
    }
  );

  const [assignMilestoneToTeam, { loading }] = useMutation(
    ManageMilestoneTeamsAssignMutationDocument
  );

  const linkedTeams = data.milestoneWithLinks.teamLinks;

  const { tenantId } = useTenantOverride();

  const [selectedTeamId, setSelectedTeamId] = useState<string | undefined>();

  const handleAssign = () => {
    if (!selectedTeamId || !tenantId) return;
    assignMilestoneToTeam({
      variables: {
        teamId: selectedTeamId,
        domainId: {
          itemId: milestoneId,
          tenantId: tenantId,
        },
        type: TeamLinkTypes.MILESTONE,
      },
      onCompleted: () => {
        setSelectedTeamId(undefined);
        showNotification('success', {
          message: (
            <span className="bold">
              {t('ManageMilestoneTeams.assignedSuccessfully')}
            </span>
          ),
        });
      },
      onError: (error) => {
        const howweErrors = howweErrorParser(error);

        showNotification('error', {
          message: (
            <strong>
              <ul>
                {howweErrors?.map((e, i) => <li key={i}>{e.translation}</li>)}
              </ul>
            </strong>
          ),
        });
      },
      refetchQueries: [
        {
          query: ManageMilestoneTeamsQueryDocument,
          variables: { milestoneId },
        },
      ],
    });
  };

  return (
    <div>
      <h3 className="mb--s">{t('ManageMilestoneTeams.modalTitle')}</h3>

      <h5 className="mb--xl">{milestoneName}</h5>
      <div className="flx mb--xl">
        <PermittedTeamSelectorForAction
          value={selectedTeamId}
          alreadySelectedTeams={linkedTeams.map((link) => link.team.id)}
          teamResourceType={TeamResourceType.TEAM_LINKS}
          action={Action.CREATE}
          className="flx--1 mr"
          onChange={setSelectedTeamId}
          alreadySelectedText={t('ManageMilestoneTeams.alreadySelected')}
        />

        <Btn
          icon={<PlusOutlined />}
          disabled={!selectedTeamId}
          loading={loading}
          type="primary"
          onClick={handleAssign}
        >
          {t('ManageMilestoneTeams.assignTeamButton')}
        </Btn>
      </div>
      <h3 className="mb"> {t('ManageMilestoneTeams.assignedTableHeader')}</h3>
      <Table
        loading={networkStatus === NetworkStatus.refetch}
        size="middle"
        pagination={false}
        dataSource={data.milestoneWithLinks.teamLinks}
        rowKey={(record) => record.team.id}
        columns={[
          {
            title: t('common.name'),
            dataIndex: 'team',
            key: 'team',
            render: (team) => team.name,
          },
          {
            defaultSortOrder: 'ascend',
            key: 'actions',
            width: 150,

            render: (_, record) => {
              return (
                <RemoveTeamLink
                  domainId={{
                    itemId: milestoneId,
                    tenantId: record.team.tenantId,
                  }}
                  teamId={record.team.id}
                  linkType={TeamLinkTypes.MILESTONE}
                  mutationOptions={{
                    refetchQueries: [
                      {
                        query: ManageMilestoneTeamsQueryDocument,
                        variables: { milestoneId },
                      },
                    ],
                  }}
                />
              );
            },
          },
        ]}
      />
    </div>
  );
};

ManageMilestoneTeams.Skeleton = ManageMilestoneTeamsSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MANAGE_MILESTONE_TEAMS_QUERY = gql`
  query manageMilestoneTeamsQuery($milestoneId: ID!) {
    milestoneWithLinks(milestoneId: $milestoneId) {
      id
      name
      metadata {
        status
        archived
        completedAt
      }
      teamLinks {
        id
        team {
          id
          tenantId
          name
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ASSIGN_MILESTONE_TO_TEAM = gql`
  mutation manageMilestoneTeamsAssignMutation(
    $tenantId: ID
    $teamId: ID!
    $domainId: TeamLinkDomainIdInput!
    $type: TeamLinkTypes!
  ) {
    createLinkToTeam(
      tenantId: $tenantId
      teamId: $teamId
      domainId: $domainId
      type: $type
    ) {
      id
    }
  }
`;
