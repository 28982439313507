import { PermittedTeamSelectorForAction } from '../../../../../appPages/company/strategicPlan/components/PermittedTeamSelectorForAction';
import { Action, TeamResourceType } from '../../../../../generated/graphql';
import { Btn } from '../../../../Button';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export const EnrollTeamToInitiativeSkeleton = () => {
  const { t } = useTranslation();

  return (
    <div className="flx">
      <PermittedTeamSelectorForAction
        teamResourceType={TeamResourceType.INITIATIVE}
        action={Action.CREATE}
        className="flx--1 mr"
        alreadySelectedText={t('EnrollTeamToInitiative.alreadySelected')}
      />

      <Btn icon={<PlusOutlined />} disabled={true} type="primary">
        {t('EnrollTeamToInitiative.enrollTeam')}
      </Btn>
    </div>
  );
};
