import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export const exportReportToPdf = async () => {
  const reportContainer = document.getElementById('report-container');

  if (reportContainer) {
    const pdf = new jsPDF({
      orientation: 'landscape', // Switch to landscape
      unit: 'mm',
      format: 'a4',
    });

    pdf.setFontSize(10);

    const pageWidth = 297; // A4 width in landscape (mm)
    const pageHeight = 210; // A4 height in landscape (mm)
    const pageMargin = 10; // Margins for PDF
    const usableWidth = pageWidth - pageMargin * 2; // Usable width with margins
    const usableHeight = pageHeight - pageMargin * 2; // Usable height with margins

    const sections = Array.from(reportContainer.children); // All top-level report sections
    let currentPageHeight = pageMargin;

    for (const section of sections) {
      const sectionChildren = Array.from(section.children); // Direct children of each section

      for (const element of sectionChildren) {
        if (element.classList.contains('exclude-from-pdf')) {
          continue; // Skip excluded elements
        }

        const hasPageBreak = element.classList.contains('page-break');
        const isVerticallyCentered =
          element.classList.contains('vertically-center');

        const canvas = await html2canvas(element as HTMLElement, {
          scale: 3,
          useCORS: true,
        });

        const imgData = canvas.toDataURL('image/png');
        const imgWidth = usableWidth;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        if (imgHeight === 0) {
          continue; // Skip empty elements
        }

        if (hasPageBreak) {
          // Force a new page for page-break
          pdf.addPage();
          currentPageHeight = pageMargin;
        }

        if (imgHeight > usableHeight) {
          // Content too large for one page, split it
          let offsetY = 0;
          while (offsetY < canvas.height) {
            const sliceCanvas = document.createElement('canvas');
            sliceCanvas.width = canvas.width;
            sliceCanvas.height = Math.min(
              canvas.height - offsetY,
              (usableHeight * canvas.width) / imgWidth
            );

            const ctx = sliceCanvas.getContext('2d');
            if (ctx) {
              ctx.drawImage(
                canvas,
                0,
                offsetY,
                canvas.width,
                sliceCanvas.height,
                0,
                0,
                canvas.width,
                sliceCanvas.height
              );

              const sliceImgData = sliceCanvas.toDataURL('image/png');
              pdf.addImage(
                sliceImgData,
                'JPEG',
                pageMargin,
                pageMargin,
                imgWidth,
                (sliceCanvas.height * imgWidth) / canvas.width,
                undefined,
                'FAST'
              );
            }

            offsetY += sliceCanvas.height;
            if (offsetY < canvas.height) {
              pdf.addPage();
            }
          }

          currentPageHeight = pageMargin; // Reset for next element
        } else if (isVerticallyCentered && imgHeight < usableHeight) {
          // Vertically center the content if flagged
          const centeredY = (pageHeight - imgHeight) / 2; // Center vertically
          pdf.addImage(
            imgData,
            'JPEG',
            pageMargin,
            centeredY,
            imgWidth,
            imgHeight,
            undefined,
            'FAST'
          );
          currentPageHeight = pageHeight; // Mark end of the page
        } else {
          // Add the image to the PDF at the current position
          if (currentPageHeight + imgHeight > usableHeight) {
            // Add a new page if content overflows
            pdf.addPage();
            currentPageHeight = pageMargin;
          }

          pdf.addImage(
            imgData,
            'JPEG',
            pageMargin,
            currentPageHeight,
            imgWidth,
            imgHeight,
            undefined,
            'FAST'
          );
          currentPageHeight += imgHeight; // Update height for the next element
        }
      }
    }

    // Save the generated PDF
    pdf.save('Report.pdf');
  }
};
