import { Form, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { TeamMemberRoles } from '../../../generated/graphql';

const radioStyle = { display: 'block', marginBottom: 4 };
const fixZeroHeight = { marginBottom: 12 };

export const TeamRolesFormItem = () => {
  const { t } = useTranslation();

  return (
    <Form.Item name="teamRole">
      <Radio.Group>
        <Radio style={radioStyle} value={null}>
          <span className="bold">{t('common.teamRole.member')}</span>
          <div className="mb" style={fixZeroHeight}>
            {t('TeamRolesFormItem.memberDescription')}
          </div>
        </Radio>

        <Radio style={radioStyle} value={TeamMemberRoles.KA_EDITOR}>
          <span className="bold">{t('common.teamRole.sprint_admin')}</span>
          <div style={fixZeroHeight}>
            {t('TeamRolesFormItem.sprintAdminDescription')}
          </div>
        </Radio>

        <Radio style={radioStyle} value={TeamMemberRoles.TEAM_ADMIN}>
          <span className="bold">{t('common.teamRole.team_admin')}</span>
          <div style={fixZeroHeight}>
            {t('TeamRolesFormItem.teamAdminDescription')}
          </div>
        </Radio>

        <Radio style={radioStyle} value={TeamMemberRoles.TEAM_LEADER}>
          <span className="bold">{t('common.teamRole.leader')}</span>
          <div style={fixZeroHeight}>
            {t('TeamRolesFormItem.leaderDescription')}
          </div>
        </Radio>
      </Radio.Group>
    </Form.Item>
  );
};
