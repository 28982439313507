import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CreateMigLinkToTenantDocument,
  GetGlobalMigsDocument,
  GetLinkedMigsDocument,
  ManageCompanyMigs_MigV2Fragment,
  TenantLinkTypes,
} from '../../../../../generated/graphql';
import { Btn } from '../../../../Button';
import { gql, useMutation, useSuspenseQuery } from '@apollo/client';
import { showNotification } from '../../../../../services/fetchNotificationProperties';
import { ErrorAlert } from '../../../../ErrorAlert';
import { TeamMigSelect } from '../../../InitiativeForm/teamMigSelector/TeamMigSelect';
import { TeamMig } from '../../../InitiativeForm/TeamMigSelector';
import { Alert, Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { without } from '../../../../../services/without';

import { ManageCompanyMigsSkeleton } from './ManageCompanyMigs.skeleton';
import { getColorForMigStatus } from '../../../../../appPages/team/overview/components/KeyActivitySection/components/wkaSection/components/wkaDetails/wkaDetailsSection/getColorForMigStatus';
import { RemoveMigLinkToTenant } from './RemoveMigLinkToTenant';
import { TeamSelector } from '../../../../../appPages/company/strategicPlan/components/TeamSelector';
import { LinkOutlined } from '@ant-design/icons';

export const ManageCompanyMigs = () => {
  const { t } = useTranslation();
  const [selectedTeamId, setSelectedTeamId] = useState<string | undefined>(
    undefined
  );
  const [selectedTeamMig, setSelectedTeamMig] = useState<TeamMig | undefined>(
    undefined
  );

  const { data } = useSuspenseQuery(GetLinkedMigsDocument);
  const linkedMigs = data.globalMigs.globalMigs;

  const [createMigLinkToTenant, { loading: createLoader }] = useMutation(
    CreateMigLinkToTenantDocument,
    {
      onCompleted: () => {
        showNotification('success', {
          message: <strong>{t('ManageCompanyMigs.linkSuccess')}</strong>,
        });
        setSelectedTeamId(undefined);
      },
      onError: (error) => {
        showNotification('error', {
          message: <strong>{t('ManageCompanyMigs.linkFailed')}</strong>,
          description: <ErrorAlert error={error} />,
        });
      },
      refetchQueries: () => [
        {
          query: GetGlobalMigsDocument,
        },
      ],
    }
  );

  const columns: ColumnProps<ManageCompanyMigs_MigV2Fragment>[] = [
    {
      title: t('common.status'),
      dataIndex: 'status',
      key: 'status',
      render(value, record) {
        return (
          <span
            className="bold"
            style={{ color: getColorForMigStatus(record) }}
          >
            {t(`common.mig.status.${value.toLowerCase()}`)}
          </span>
        );
      },
    },
    { title: t('common.title'), dataIndex: 'name', key: 'name' },
    { title: t('common.team'), dataIndex: ['team', 'name'], key: 'team' },
    {
      key: 'actions',
      align: 'right',
      render(value, record) {
        return <RemoveMigLinkToTenant migDomainId={record.domainId} />;
      },
    },
  ];

  const handleSubmit = () => {
    if (selectedTeamMig)
      createMigLinkToTenant({
        variables: {
          domainId: without(selectedTeamMig.domainId, '__typename'),
          type: TenantLinkTypes.MIG,
        },
      });
  };

  return (
    <div>
      <Alert
        className="mb--xl"
        showIcon
        closable
        message={t('ManageCompanyMigs.helpText')}
      />

      <h3 className="mb--l">{t('ManageCompanyMigs.linkMostImportantGoal')}</h3>
      <div className="flx mb--xl">
        <TeamSelector
          className="mr"
          onChange={(teamId) => {
            setSelectedTeamId(teamId);
            setSelectedTeamMig(undefined);
          }}
          value={selectedTeamId}
        />
        <TeamMigSelect
          placeholder={t('ManageCompanyMigs.selectMig')}
          teamId={selectedTeamId}
          onSetTeamMig={setSelectedTeamMig}
          className="flx--1"
        />
        <Btn
          type="primary"
          className="ml--xl"
          icon={<LinkOutlined />}
          onClick={handleSubmit}
          loading={createLoader}
          disabled={!selectedTeamMig}
        >
          {t('ManageCompanyMigs.linkMig')}
        </Btn>
      </div>
      <h3 className="mb--l">{t('ManageCompanyMigs.currentlyLinked')}</h3>
      <Table
        className="showScrollBar"
        rowKey={(record) => record.id}
        dataSource={linkedMigs}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};

ManageCompanyMigs.Skeleton = ManageCompanyMigsSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LINK_MIG_TO_TENANT = gql`
  mutation createMigLinkToTenant(
    $tenantId: ID
    $domainId: TenantLinkDomainIdInput!
    $type: TenantLinkTypes!
  ) {
    createLinkToTenant(tenantId: $tenantId, domainId: $domainId, type: $type) {
      id
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LINK_MIG_TO_TENANT_MODAL_MIG_V2 = gql`
  fragment ManageCompanyMigs_MigV2 on MigV2 {
    id
    name
    status
    team {
      id
      name
    }
    domainId {
      itemId
      teamId
      tenantId
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TEAMS_TO_LINK_MIGS = gql`
  query getTeamsForLinkingCompanyMigs {
    teams(teamFilter: { active: true }) {
      name
      id
      created
      umbrellaTeam
      parentTeamId
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_GLOBAL_MIGS = gql`
  query getLinkedMigs {
    globalMigs {
      globalMigs {
        id
        ...ManageCompanyMigs_MigV2
      }
    }
  }
`;
