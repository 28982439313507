import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { GlobalMigsEmptyPlaceholder } from '../../strategicPlan/components/GlobalMigSection/components/GlobalMigsEmptyPlaceholder';
import { GetCompanyMigsForReportDocument } from '../../../../generated/graphql';
import { CompanyMigReport } from './CompanyMigReport';
import { Divider } from 'antd';
import cx from 'classnames';
import { ReportSection } from '../ReportSection';

interface Props {
  isPreviewMode: boolean;
}

export const CompanyMigReportSection = ({ isPreviewMode }: Props) => {
  const { t } = useTranslation();
  const { data } = useQuery(GetCompanyMigsForReportDocument);

  const globalMigs = data?.globalMigs.globalMigs || [];
  if (globalMigs.length === 0) {
    return <GlobalMigsEmptyPlaceholder />;
  }

  return (
    <ReportSection
      isPreviewMode={isPreviewMode}
      title={t('CompanyMigReportSection.title')}
      subTitle={t('CompanyMigReportSection.subTitle')}
    >
      {globalMigs.map((mig, index) => [
        <div
          key={mig.id}
          className={cx('mb--l vertically-center', {
            'page-break': index !== 0,
          })}
        >
          {index != 0 && (
            <div className="exclude-from-pdf">
              <Divider />
            </div>
          )}
          <CompanyMigReport mig={mig} isPreviewMode={isPreviewMode} />
        </div>,
      ])}
    </ReportSection>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_GLOBAL_MIGS = gql`
  query getCompanyMigsForReport {
    globalMigs {
      globalMigs {
        id
        ...CompanyMigReport_MigV2
      }
    }
  }
`;
