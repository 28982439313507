import { EditOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { Btn } from '../../../../components/Button';
import { EditSprintKeyActivityDrawer } from '../../../team/sprint/planning/components/mitemListEntry/EditSprintKeyActivityDrawer';
import { useState } from 'react';
import { gql } from '@apollo/client';
import { EditSprintKeyActivityDrawerWithButton__MitemFragment } from '../../../../generated/graphql';
import { useTranslation } from 'react-i18next';

interface Props {
  ska: EditSprintKeyActivityDrawerWithButton__MitemFragment;
}

export const EditSprintKeyActivityDrawerWithButton = ({ ska }: Props) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const { t } = useTranslation();

  return (
    <div>
      <Tooltip placement="top" title={t('common.edit')} mouseEnterDelay={0.7}>
        <Btn
          className="txt--secondary SprintMitemTableActions__action"
          onClick={() => {
            setShowEditModal(true);
          }}
          type="text"
          icon={<EditOutlined />}
        />
      </Tooltip>
      <EditSprintKeyActivityDrawer
        mitem={ska}
        showModal={showEditModal}
        onCompleted={() => {
          setShowEditModal(false);
        }}
        onCancel={() => {
          setShowEditModal(false);
        }}
      />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EditSprintKeyActivityDrawerFragment = gql`
  fragment EditSprintKeyActivityDrawerWithButton__Mitem on Mitem {
    id
    ...EditSprintKeyActivityDrawer__Mitem
  }
`;
